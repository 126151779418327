import React from 'react'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import Company from './company.js'
// import Cam from './picCam.js'
// import Fred from './picFred.js'
// import Garry from './picGarry.js'
// import Sam from './picSam'
// import Steeve from './picSteeve.js'
import {Link} from "gatsby";
import { useIntl } from "gatsby-plugin-intl"
import { StaticImage } from 'gatsby-plugin-image'

const Trust = () => {

  const intl = useIntl()

  intl.formatMessage({ id: "trust-garry-head" })
  const biz = [
    {
      img: <StaticImage src="../images/bht_garry.jpg" alt="Dr. Garry Rene" className="trustimg" />,
      name: "Dr Garry Rene",
      title: intl.formatMessage({ id: "trust-garry-head" }),
      testimony: intl.formatMessage({ id: "trust-garry" }),
      url: "https://villasantehaiti.com/"
    },
    {
      img: <StaticImage src="../images/bht_samuel.jpg" alt="Samuel M. Rodolphe" className="trustimg" />,
      name: "Samuel M. Rodolphe",
      title: intl.formatMessage({ id: "trust-sam-head" }),
      testimony: intl.formatMessage({ id: "trust-sam" }),
      url: "#"
    },
    {
      img: <StaticImage src="../images/bht_fred.jpeg" alt="Fred Samuel Bourdeau" className="trustimg" />,
      name: "Fred Samuel Bourdeau",
      title: intl.formatMessage({ id: "trust-fred-head" }),
      testimony: intl.formatMessage({ id: "trust-fred" }),
      url: "http://www.exhaustic.com/"
    },
    {
      img: <StaticImage src="../images/bht_steeve.jpg" alt="Dr. Steeve J. Symithe" className="trustimg" />,
      name: "Dr. Steeve J. Symithe",
      title: intl.formatMessage({ id: "trust-steeve-head" }),
      testimony: intl.formatMessage({ id: "trust-steeve" }),
      url: "#"
    },
    // {
    //   img: <StaticImage src={img} alt={`${title} ${name}`} className="trustimg" />,
    //   name: "Aswin Cameron",
    //   title: "CEO of Benmak LTD. (Jamaica)",
    //   testimony: "BizHighTech team is very professional. I subcontracted them for a server racking of 13 servers in Digicel and also doing initial configuration (RAID and iLO configuration) and they deliver beyond expectations. I definitely recommend them for IT works. ",
    //   url: ""
    // }
  ]

  return (
    <section className="trust color-4-bg">
      <div className="container2 color-0">
        <h3 className="center title color-0">{intl.formatMessage({ id: "trust-head" })}</h3>
        <div className="trustees">        
          <Row>
            {
              biz.map(biz => {
                return(
                  <Col l={4} m={6} s={12}>
                    <Company url={biz.url} img={biz.img} name={biz.name} title={biz.title} testimony={biz.testimony} />
                  </Col>
                )
              })
              } 
          </Row>
        </div>
        <div className="center cta-contain">
          <h4 className="color-0">{intl.formatMessage({ id: "trust-cta-text" })}<span role="img" aria-label="wink">😉</span>!</h4>
          <Link to="/contact">
            <button className="mybtn color-0-bg color-2">{intl.formatMessage({ id: "trust-cta" })}</button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Trust