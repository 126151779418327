import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Activities from "../components/activities"
import Trust from "../components/trust"

const IndexPage = () => (
  <Layout>
    <SEO title="Web & Mobile Development, IT Support, Design" />
    <Header />
    <Activities />
    <Trust />
  </Layout>
)

export default IndexPage
