import React from 'react'
import Row  from 'react-materialize/lib/Row'
import  Col from 'react-materialize/lib/Col'
import {Link} from "gatsby";
import { useIntl } from "gatsby-plugin-intl"

const Activities = () => {
  const intl = useIntl()
  return (
    <section className="activities">
      <div className="container2">
        <h3 className="center title color-1">{intl.formatMessage({ id: "activities-head" })}</h3>
        <Row>
          <Col l={4} m={6} s={12}>
            <h5 className="subtitle color-1">{intl.formatMessage({ id: "activities-sub-dev" })}</h5>
            <p className="color-1 text activity-desc">{intl.formatMessage({ id: "activities-dev" })}</p>
          </Col>
          <Col l={4} m={6} s={12}>
            <h5 className="subtitle color-1">{intl.formatMessage({ id: "activities-sub-it" })}</h5>
            <p className="color-1 text activity-desc">{intl.formatMessage({ id: "activities-it" })}</p>
          </Col>
          <Col l={4} m={6} s={12}>
            <h5 className="subtitle color-1">{intl.formatMessage({ id: "activities-sub-design" })}</h5>
            <p className="color-1 text activity-desc">{intl.formatMessage({ id: "activities-design" })}</p>
          </Col>
          <Col l={4} m={6} s={12}>
            <h5 className="subtitle color-1">{intl.formatMessage({ id: "activities-sub-biomed" })}</h5>
            <p className="color-1 text activity-desc">{intl.formatMessage({ id: "activities-biomed" })}</p>
          </Col>
          <Col l={4} m={6} s={12}>
            <h5 className="subtitle color-1">{intl.formatMessage({ id: "activities-sub-data" })}</h5>
            <p className="color-1 text activity-desc">{intl.formatMessage({ id: "activities-data" })}</p>
          </Col>
          <Col l={4} m={6} s={12}>
            <h5 className="subtitle color-1">{intl.formatMessage({ id: "activities-sub-staff" })}</h5>
            <p className="color-1 text activity-desc">{intl.formatMessage({ id: "activities-staff" })}</p>
          </Col>
        </Row>
        <div className="mycircle"></div>
        <div className="center cta-contain">
          <Link to="/services">          
            <button className="mybtn-border color-2-bg color-1">{intl.formatMessage({ id: "activities-cta" })}</button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Activities