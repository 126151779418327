
import React from 'react'


const Company = ({url, name, title, testimony, img}) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <div className="mycard company color-0-bg">
        {img}
        
        <div className="company-info color-2">
          <h5 className="card-name color-3">{name}</h5>
          <h6 className="card-title">{title}</h6>
          <p className="card-testimony">{testimony}</p>
        </div>
      </div>
    </a>
  )
}

export default Company