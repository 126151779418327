// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React, {useEffect} from "react"
// import Image from "./image"
import { gsap } from 'gsap'
import Typed from 'react-typed';
import { useIntl } from "gatsby-plugin-intl"
import { StaticImage } from "gatsby-plugin-image";

const Header = () => {

  useEffect(() => {
    gsap.from("#welcome", {opacity: 0, duration: .8, y: 20, ease: "back", delay: .6})
  },[])

  const intl = useIntl()
  
  return (<header>
    <svg className="Path_1" viewBox="33.622 94.567 678.787 339.905">
      <path fill="rgba(255,255,255,1)" id="Path_1" d="M 49.31192779541016 219.7846832275391 C 49.31192779541016 219.7846832275391 -11.0281982421875 338.4934387207031 104.9544448852539 357.9483642578125 C 220.9370880126953 377.4032592773438 163.1288452148438 425.3913269042969 234.2646331787109 433.653076171875 C 305.4004211425781 441.914794921875 298.7366333007813 385.6648864746094 368.7062683105469 357.9483642578125 C 437.6763610839844 334.5695190429688 537.8628540039063 448.319580078125 585.6754150390625 364.9995727539063 C 633.488037109375 281.6795349121094 713.3995361328125 348.6791687011719 712.4000244140625 265.5348205566406 C 711 193.7851715087891 492.0780944824219 124.7312545776367 402.6585693359375 99.5506591796875 C 313.2390441894531 74.37006378173828 274.8806762695313 152.9880065917969 183.7535705566406 149.4723815917969 C 92.62645721435547 145.956787109375 49.31192779541016 219.7846832275391 49.31192779541016 219.7846832275391 Z">
      </path>
    </svg>
    <div id="Hand">
      <svg className="Path_3" viewBox="33.622 94.567 941.114 337.129">
        <path fill="rgba(255,255,255,1)" id="Path_3" d="M 55.37548828125 218.7620086669922 C 55.37548828125 218.7620086669922 -28.28396034240723 336.5012817382813 132.5218811035156 355.7973327636719 C 293.3276977539063 375.0932922363281 213.1786346435547 422.689453125 311.8058776855469 430.8837280273438 C 410.4331359863281 439.0779724121094 401.1940612792969 383.2874450683594 498.2044982910156 355.7973327636719 C 593.8291015625 332.6094360351563 732.7341918945313 445.4304504394531 799.0245361328125 362.7909545898438 C 865.3150024414063 280.1513671875 976.1094970703125 346.6038208007813 974.7236328125 264.1385192871094 C 972.7825927734375 192.9748077392578 669.2551879882813 124.4849014282227 545.2781982421875 99.50995635986328 C 421.3011474609375 74.53501892089844 368.1185913085938 152.5108489990234 241.7740783691406 149.0239410400391 C 115.4295349121094 145.5370483398438 55.37548828125 218.7620086669922 55.37548828125 218.7620086669922 Z">
        </path>
      </svg>
      <StaticImage src="../images/hand.png" className="hand" alt="A helping hand" placeholder="tracedSVG" />
      {/* <Image /> */}
    </div>
    <svg className="Path_4" viewBox="0 -16.815 500.261 306.937">
      <path fill="rgba(255,255,255,1)" id="Path_4" d="M 12.95015335083008 183.2444305419922 C 12.95015335083008 183.2444305419922 -36.85367965698242 81.92205047607422 58.87664794921875 65.31654357910156 C 154.6069946289063 48.71106719970703 118.4661102294922 12.83927059173584 166.4261322021484 -12.01272296905518 C 214.3861541748047 -36.86471557617188 218.8215942382813 41.65948867797852 276.5734558105469 65.31654357910156 C 333.5003356933594 85.27123260498047 416.1927795410156 -11.81865310668945 455.6565246582031 59.29806137084961 C 495.1203308105469 130.4148559570313 561.078125 73.2281494140625 560.2531127929688 144.1949615478516 C 559.0975952148438 205.4359741210938 378.4027099609375 264.3760375976563 304.59716796875 285.8686218261719 C 230.7916259765625 307.3611755371094 199.1312255859375 240.2578735351563 123.9162673950195 243.2585754394531 C 48.70130920410156 246.25927734375 12.95015335083008 183.2444305419922 12.95015335083008 183.2444305419922 Z">
      </path>
    </svg>
    <div id="welcome">
      <div className="mycarousel">
        <div className="pre">{intl.formatMessage({ id: "headertxt" })}</div>
        <div className="change_outer">
          <Typed
            strings={[
                `${intl.formatMessage({ id: "headertxt1" })}`,
                `${intl.formatMessage({ id: "headertxt2" })}`,
                `${intl.formatMessage({ id: "headertxt3" })}`,
                `${intl.formatMessage({ id: "headertxt4" })}`,
                `${intl.formatMessage({ id: "headertxt5" })}`]}
                typeSpeed={90}
                backSpeed={50}
                // attr="placeholder"
                loop >
    
          </Typed>
        </div>
      </div>
    </div>
    <svg className="Path_2" viewBox="0 669.648 1366 98.352">
      <path fill="rgba(255,255,255,1)" id="Path_2" d="M 0 768 C 0 768 72.375 623.7517700195313 337.486083984375 684.6406860351563 C 602.59716796875 745.5296020507813 595.0159301757813 728.5090942382813 716.17724609375 730.667236328125 C 930.1363525390625 730.667236328125 988.67529296875 676.734375 1103.670776367188 672.418212890625 C 1218.666259765625 668.10205078125 1366.000122070313 708.1614990234375 1366.000122070313 708.1614990234375 L 1366.000122070313 768 L 0 768 Z">
      </path>
    </svg>

  </header>
)}



export default Header
